import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import InfoSlide from './info-slide';
import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';

function SiteShoppingInfoDesktop({ type, elements, lazyLoad }) {
  return (
    <Section type={type}>
      <div className="container">
        {elements.map(element => (
          <InfoSlide key={element.title_label} {...element} lazyLoad={lazyLoad} />
        ))}
      </div>
    </Section>
  );
}

SiteShoppingInfoDesktop.propTypes = {
  elements: arrayOf(
    shape({
      title_label: string.isRequired,
    }),
  ).isRequired,
  type: string.isRequired,
  lazyLoad: bool.isRequired,
};

export default withTracker(SiteShoppingInfoDesktop);
